<template>
  <div>
    <div class="inputColor">
      <v-text-field
        :value="cor"
        readonly
        :color="cor"
        :label="label"
        required
        class="mt-n1 field"
      ></v-text-field>
      <v-sheet
        :color="cor"
        @click="select = !select"
        class="labelColor"
        width="25"
        height="25"
      ></v-sheet>
      <v-color-picker
        v-if="select"
        class="picker"
        v-model="cor"
        :hide-canvas="false"
        :hide-inputs="true"
        :hide-mode-switch="true"
        @input="$emit('setColor', cor)"
      ></v-color-picker>
    </div>
  </div>
</template>

<script>
export default {
  props: ["color", "label"],
  data() {
    return {
      cor: this.color,
      select: false,
    };
  },
  name: "ColorPicker",
  created() {
    setTimeout(() => {
      this.cor = this.color ? this.color : "#000000";
    }, 100);
  },
};
</script>

<style>
.inputColor {
  width: 100%;
  position: relative;
}
.labelColor {
  position: absolute;
  top: 14px;
  right: 10px;
  cursor: pointer;
}

.field {
  width: 100%;
}

.picker {
  position: absolute;
  top: 45px;
  left: 0;
  z-index: 9;
}
</style>
